var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"view view--day"},[_c('section',{staticClass:"header"},[_c('div',{staticClass:"header__left"},[(!_vm.is_offline)?_c('button',{staticClass:"btn btn-refresh",on:{"click":function($event){return _vm.refresh()}}}):_vm._e()]),_c('div',{staticClass:"header__center"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(this.$helper.ucfirst(_vm.date.format("dddd"))))]),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.date.format("DD/MM/YYYY")))])]),_c('div',{staticClass:"header__right"},[_c('button',{staticClass:"btn btn-close",on:{"click":function($event){_vm.$router.push({
            name: 'week',
            query: { date: _vm.date.format('YYYY-MM-DD') },
          })}}})])]),_c('section',[(_vm.tracking_total > 0 || _vm.tracking_pause > 0)?_c('div',{staticClass:"timeregistration-summary timeregistration-summary-full"},[(_vm.tracking_total > 0)?_c('span',[_vm._v(_vm._s(_vm.$t("Total"))+": "),_c('strong',[_vm._v(_vm._s(_vm.$moment .duration(_vm.tracking_total, "milliseconds") .format("HHumm", { trim: false })))])]):_vm._e(),(_vm.tracking_pause > 0)?_c('span',[_vm._v(_vm._s(_vm.$t("Break"))+": "),_c('strong',[_vm._v(_vm._s(_vm.$moment .duration(_vm.tracking_pause, "milliseconds") .format("HHumm", { trim: false })))])]):_vm._e()]):_vm._e()]),(_vm.not_today)?_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("You are viewing a task that is not planned for today."))+" ")]):_vm._e(),(_vm.is_offline)?_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t( "You don't have an internet connection at the moment. Please reconnect to get the latest data." ))+" ")]):_vm._e(),(_vm.timetrackings.length > 0)?_c('section',{staticClass:"tasks"},_vm._l((_vm.timetrackings),function(tracking,index){return _c('router-link',{key:index,staticClass:"task",class:_vm.iconClass(tracking),attrs:{"to":{
        name: 'task',
        query: { date: _vm.date.format('YYYY-MM-DD'), slot: tracking.slot_id },
      }}},[_c('div',{staticClass:"task__time"},[_vm._v(" "+_vm._s(_vm.formatHour(tracking.start))),(tracking.tracking != null || _vm.showEndtime)?_c('span',[_vm._v(" - "+_vm._s(_vm.formatHour(tracking.end)))]):_vm._e(),(tracking.tracking == null && !_vm.showEndtime)?_c('span',[_vm._v(" - ...")]):_vm._e()]),_c('div',{staticClass:"task__info"},[_c('strong',{staticClass:"single-line"},[_vm._v(_vm._s(tracking.task.title))]),_c('br'),(tracking.task.relatable_type == 'project')?_c('em',{staticClass:"single-line"},[(tracking.task.relatable.internal_id)?_c('span',[_vm._v(_vm._s(tracking.task.relatable.internal_id)+" - ")]):_vm._e(),_vm._v(_vm._s(tracking.task.relatable.title))]):_vm._e()])])}),1):_vm._e(),(_vm.timetrackings.length == 0)?_c('div',{staticClass:"message"},[_c('div',{staticClass:"message__text"},[_vm._v(" "+_vm._s(_vm.$t("There are no tasks for selected date"))+". ")])]):_vm._e(),(_vm.settings.can_track_time_for_unplanned_task == 1 && !_vm.is_offline)?_c('button',{staticClass:"btn-add btn-add--lg",on:{"click":_vm.goToNewTask}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }