<template>
  <main class="view view--day">
    <section class="header">
      <div class="header__left">
        <button
          v-if="!is_offline"
          v-on:click="refresh()"
          class="btn btn-refresh"
        ></button>
      </div>

      <div class="header__center">
        <p class="title">{{ this.$helper.ucfirst(date.format("dddd")) }}</p>
        <p class="subtitle">{{ date.format("DD/MM/YYYY") }}</p>
      </div>

      <div class="header__right">
        <button
          v-on:click="
            $router.push({
              name: 'week',
              query: { date: date.format('YYYY-MM-DD') },
            })
          "
          class="btn btn-close"
        ></button>
      </div>
    </section>
    <section>
      <div
        v-if="tracking_total > 0 || tracking_pause > 0"
        class="timeregistration-summary timeregistration-summary-full"
      >
        <span v-if="tracking_total > 0"
          >{{ $t("Total") }}:
          <strong>{{
            $moment
              .duration(tracking_total, "milliseconds")
              .format("HHumm", { trim: false })
          }}</strong></span
        >
        <span v-if="tracking_pause > 0"
          >{{ $t("Break") }}:
          <strong>{{
            $moment
              .duration(tracking_pause, "milliseconds")
              .format("HHumm", { trim: false })
          }}</strong></span
        >
      </div>
    </section>
    <div v-if="not_today" class="alert alert-warning">
      {{ $t("You are viewing a task that is not planned for today.") }}
    </div>
    <div v-if="is_offline" class="alert alert-warning">
      {{
        $t(
          "You don't have an internet connection at the moment. Please reconnect to get the latest data."
        )
      }}
    </div>
    <section v-if="timetrackings.length > 0" class="tasks">
      <router-link
        :to="{
          name: 'task',
          query: { date: date.format('YYYY-MM-DD'), slot: tracking.slot_id },
        }"
        class="task"
        v-for="(tracking, index) in timetrackings"
        :key="index"
        :class="iconClass(tracking)"
      >
        <div class="task__time">
          {{ formatHour(tracking.start)
          }}<span v-if="tracking.tracking != null || showEndtime"
            >&nbsp;-&nbsp;{{ formatHour(tracking.end) }}</span
          ><span v-if="tracking.tracking == null && !showEndtime"
            >&nbsp;-&nbsp;...</span
          >
        </div>
        <div class="task__info">
          <strong class="single-line">{{ tracking.task.title }}</strong
          ><br /><em
            class="single-line"
            v-if="tracking.task.relatable_type == 'project'"
            ><span v-if="tracking.task.relatable.internal_id"
              >{{ tracking.task.relatable.internal_id }} - </span
            >{{ tracking.task.relatable.title }}</em
          >
        </div>
      </router-link>
    </section>

    <div class="message" v-if="timetrackings.length == 0">
      <div class="message__text">
        {{ $t("There are no tasks for selected date") }}.
      </div>
    </div>

    <button
      v-if="settings.can_track_time_for_unplanned_task == 1 && !is_offline"
      v-on:click="goToNewTask"
      class="btn-add btn-add--lg"
    ></button>
  </main>
</template>

<script>
export default {
  name: "day",
  mounted: function () {
    if (!this.$route.query.date) {
      this.$router.push({ name: "home" });
      return;
    }

    var self = this;

    this.$api
      .getTrackings(
        this.$config.get("account").id,
        this.date.format("YYYY-MM-DD"),
        this.date.format("YYYY-MM-DD")
      )
      .then((timetrackings) => {
        self.fillTrackings(timetrackings);
      })
      .catch(function () {
        self.$database.readAllData("timetrackings").then((timetrackings) => {
          self.fillTrackings(timetrackings);
        });
      });

    this.$config.checkOnline(function (online) {
      self.is_offline = !online;
    });

    this.$pwa.getSettings().then((settings) => {
      self.settings = settings;
      if (
        self.date >
        self.$moment().add(self.settings.days_to_see_in_the_future, "day")
      ) {
        self.$router.push({
          name: "week",
          query: { date: self.date.format("YYYY-MM-DD") },
        });
        return;
      }
      self.$forceUpdate();
    });
  },
  methods: {
    calculate_total: function () {
      var total_tracking = 0;

      for (let j = 0; j < this.timetrackings.length; j++) {
        const tracking = this.timetrackings[j];
        if (tracking.tracking !== null) {
          let end = this.$moment(tracking.end, "HH:mm:ss");
          let start = this.$moment(tracking.start, "HH:mm:ss");
          var duration = this.$moment
            .duration(end.diff(start))
            .subtract(tracking.pause);

          total_tracking += duration;
        }
      }

      this.tracking_total = total_tracking;
    },
    calculate_pause: function () {
      var pause_tracking = 0;
      for (let j = 0; j < this.timetrackings.length; j++) {
        const tracking = this.timetrackings[j];
        if (tracking.tracking !== null) {
          var duration = this.$moment.duration(tracking.pause);
          pause_tracking += duration;
        }
      }

      this.tracking_pause = pause_tracking;
    },
    fillTrackings: function (timetrackings) {
      var day_trackings = timetrackings[this.date.format("YYYY-MM-DD")];
      var self = this;

      if (typeof day_trackings != "undefined") {
        this.timetrackings = day_trackings;
        this.timetrackings.sort(function (a, b) {
          return self.$moment(a.start, "HH:mm:ss") >
            self.$moment(b.start, "HH:mm:ss")
            ? 1
            : -1;
        });

        this.calculate_total();
        this.calculate_pause();
      } else {
        this.timetrackings = [];
      }
    },
    formatHour: function (time) {
      return this.$moment(time, "HH:mm:ss").format("HHumm");
    },
    iconClass: function (tracking) {
      return {
        "task--danger":
          this.$moment(
            tracking.date + " " + tracking.end,
            "YYYY-MM-DD HH:mm:ss"
          ) < this.$moment() && tracking.tracking == null,
        "task--success": tracking.tracking != null,
        "task--finished":
          tracking.task !== null && tracking.task.finished_at !== null,
      };
    },
    refresh: function () {
      location.reload();
    },
    goToNewTask: function () {
      this.$router.push({
        name: "tasknew",
        query: { date: this.$route.query.date },
      });
    },
  },
  computed: {
    not_today: function () {
      return (
        this.date.format("YYYY-MM-DD") != this.$moment().format("YYYY-MM-DD")
      );
    },
    showEndtime: function () {
      if (this.settings.show_end_time_of_task) {
        return this.settings.show_end_time_of_task == 1;
      } else {
        return false;
      }
    },
  },
  data: function () {
    return {
      tracking_total: 0,
      tracking_pause: 0,
      date: this.$moment(this.$route.query.date),
      timetrackings: [],
      settings: { can_track_time_for_unplanned_task: 0 },
      is_offline: false,
    };
  },
};
</script>
